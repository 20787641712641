// Dependencies
import React, { useContext, Fragment } from 'react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
	AccordionItemState,
} from 'react-accessible-accordion'

// Context
import AppContext from '../../Context/App/appContext'

function SwitchSteps() {
	// Context
	const appContext = useContext(AppContext)
	const { image_url, business, cellSplit } = appContext

	// Destructuring Variables
	const { businessStepsHeading, businessStepsListItems } = business
	const { colorHex: bgColor } = business.businessStepsBgColor[0].flatData

	return (
		<Fragment>
			{business && (
				<div className='business-steps' id='business-steps' style={{ backgroundColor: `${bgColor}` }}>
					<div className='bg-top' style={{ backgroundColor: `${bgColor}` }}></div>
					<div className='business-steps-container' style={{marginBottom : '-40px'}}>
						<h2 style={{marginTop : '-60px'}}>How to get your<br />Bank Confirmation Letter </h2>
						<div className='stepsPhoneContainer'>
							<div className='stepsPhoneItemContainer'>
								<div className='stepsPhoneHeader'>
									<img  src='https://cms.computicket.com/api/assets/mma/b17494e9-cc17-4a49-8476-2e56e1dec2ca/red1.png?version=0'></img>
									<div className='stepsPhoneHeaderText'>Profile -<br/> My Documents</div>

								</div>
								<div className='stepsPhoneImage'>
									<img src='https://cms.computicket.com/api/assets/mma/7341c0f8-e28c-420d-b08b-257f8275836d/cell1.png?version=0'></img>

								</div>

							</div>
							<div className='stepsPhoneItemContainer'>
								<div className='stepsPhoneHeader'>
									<img  src='https://cms.computicket.com/api/assets/mma/ce083d7b-752f-4e0d-9f0d-386ad10e2b9d/red2.png?version=0'></img>
									<div className='stepsPhoneHeaderText'>Bank <br/>Confirmation Letter</div>

								</div>
								<div className='stepsPhoneImage'>
									<img src='https://cms.computicket.com/api/assets/mma/41153b42-fcbb-40da-9cee-50508c24c95d/c2.png?version=0'></img>

								</div>

							</div>
							<div className='stepsPhoneItemContainer'>
								<div className='stepsPhoneHeader'>
									<img  src='https://cms.computicket.com/api/assets/mma/a94ce49e-d93c-4efb-8ad5-ed970c90d2f6/red3.png?version=0'></img>
									<div className='stepsPhoneHeaderText'>View or<br/> Share</div>

								</div>
								<div className='stepsPhoneImage'>
									<img src='https://cms.computicket.com/api/assets/mma/72bd38c0-1e69-4e66-a7ca-f7303e233ade/c3.png?version=0'></img>

								</div>

							</div>
							<div className='stepsPhoneItemContainer'>
								<div className='stepsPhoneHeader'>
									<img  src='https://cms.computicket.com/api/assets/mma/a13de4e2-029a-43d6-98b0-f20fe0a72635/red4.png?version=0'></img>
									<div className='stepsPhoneHeaderText'>Download<br/>&nbsp;</div>

								</div>
								<div className='stepsPhoneImage'>
									<img src='https://cms.computicket.com/api/assets/mma/02d01178-b548-4fb9-b506-acdc581fd6d3/p4.png?version=0'></img>

								</div>

							</div>
						</div>

						<h2 style={{margin : '60px 0px'}}>Understanding your letter</h2>

						<div className='understandingLetter'>
						<picture >
									<source media="(min-width: 760px)" srcSet="https://cms.computicket.com/api/assets/mma/bb53e4ac-f206-4f3d-90fa-a055aa4b78c8/bankimage.png?version=0" />
									<img src="https://cms.computicket.com/api/assets/mma/bb53e4ac-f206-4f3d-90fa-a055aa4b78c8/bankimage.png?version=0" alt="IfItDoesntMatchAnyMedia" />
								</picture>
						</div>

					</div>
					<div className='bg-bot' style={{ backgroundColor: `${bgColor}` }}></div>
				</div>
			)}
		</Fragment>
	)
}

export default SwitchSteps
