// ===============================================================
// ---------------------------------------------------------------
// Dependencies
import { useContext, useState, useEffect, useRef } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

// Modules
import Button from './Button'

// Context
import AppContext from '../../Context/App/appContext'



// ===============================================================
const SwitchStepsTabs = () => {
	// ---------------------------------------------------------------
	// Context
	const appContext = useContext(AppContext)
	const { image_url, business } = appContext
	const [tabState, setTabState] = useState(0);

	useEffect(() => {

		const hash = window.location.hash;
		if (hash) {
			setTimeout(() => {
				if (hash === '#sasa' || hash === '#sassa') {
					const element = document.getElementById('grantTopDiv');
					const yOffset = -105; // adjust this value to change the offset from the top
					const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
					window.scrollTo({ top: y, behavior: 'smooth' });
					setTabState(0);
				}
				if (hash === '#srd') {
					const element = document.getElementById('grantTopDiv');
					const yOffset = -105; // adjust this value to change the offset from the top
					const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
					window.scrollTo({ top: y, behavior: 'smooth' });
					setTabState(1)

				}
				if (hash === '#salary') {
					const element = document.getElementById('grantTopDiv');
					const yOffset = -105; // adjust this value to change the offset from the top
					const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
					window.scrollTo({ top: y, behavior: 'smooth' });
					setTabState(2)
				}
			}, 400);
		}
	}, []);
	// ---------------------------------------------------------------
	// Destructured Variables
	const { businessTabsHeading, businessTabsPanels, businessTabsBgColor } = business
	let width = window.innerWidth;
	return (
		<div id="grantTopDiv" className='business-tabs' style={{ backgroundColor: `${businessTabsBgColor[0].flatData.colorHex}`, marginBottom: '-80px' }}>
			<div className='bg-top' style={{ backgroundColor: `${businessTabsBgColor[0].flatData.colorHex}` }}></div>
			<h2 style={{ marginTop: '-60px', marginBottom: '30px' }}> Switching is easy</h2>
			<div className='switchTabSubHeader' style={{ marginBottom: '15px' }}><b>What would you like to switch?</b> Click a button below on how to switch.</div>
			<Tabs selectedIndex={tabState}>
				<TabList>
					<div className='swithTabButtonMainContainer'>

						<Tab onClick={() => setTabState(0)}
							className={`swithTabButton`} style={{ backgroundColor: tabState === 0 ? '#5F2F65' : '' }} key="tab1"
						>
							<h6>{width > 1000 ? 'Switch your SASSA' : 'SASSA'}</h6>

						</Tab>
						<Tab onClick={() => setTabState(1)}
							className={`swithTabButton`} style={{ backgroundColor: tabState === 1 ? '#57606F' : '' }} key="tab2"
						>
							<h6>{width > 1000 ? 'Switch your SRD grant' : 'SRD grant'}</h6>

						</Tab>
						<Tab onClick={() => setTabState(2)}
							className={`swithTabButton`} style={{ backgroundColor: tabState === 2 ? '#0A9BA9' : '' }} key="tab3"
						>
							<h6>{width > 1000 ? 'Switch your salary' : 'Salary'}</h6>
						</Tab>
					</div>
				</TabList>




				<TabPanel key="tab1">
					<div
						id='business-tabs-content' style={{ display: 'block' }}
						className={`business-tabs-content business-tabs-content-recipients`}
					>
						<div className='business-tabs-switch-content-text switchTabInsideHeader' style={{ paddingTop: '50px' }}>
							<h2 style={{ margin: 'auto' }}> Switch your SASSA </h2>
						</div>

						{/* <p className='fade-text'> "test" </p> */}
						<div className='switchStepsTabItemContainer'>
							{/* <div className='switchStepsTabItemContainerSubHead'>Get <b>R100 VOUCHERS*</b> when your first SASSA grant greater than R800 is paid into your Money Market Account!</div> */}

							<div className='switchStepTabItem'>
								<img style={{ width: '46px' }} src='https://cms.computicket.com/api/assets/mma/1707b174-adf3-409e-af49-483c108adba7/sa1.png?version=0' />

								<div className='switchStepTabText'>
									<div><b>REGISTER:</b></div>
									{/* <div >
										Dial <b>*120*3534#</b> or <a style={{ borderBottom: '1px solid #aaa', fontWeight: 'bold' }} target="_blank" rel='noreferrer' href='https://bit.ly/2SSMKCT'>WhatsApp 087 240 5709</a> <b />  or <a style={{ borderBottom: '1px solid #aaa', fontWeight: 'bold' }} target="_blank" rel='noreferrer' href='https://bit.ly/3nni5HM'>download</a> <b /> the Shoprite app
									</div> */}
									<div >
										<a style={{ borderBottom: '1px solid #aaa', fontWeight: 'bold' }} target="_blank" rel='noreferrer' href='https://bit.ly/3nni5HM'>Download</a> <b /> the Shoprite app or <a style={{ borderBottom: '1px solid #aaa', fontWeight: 'bold' }} target="_blank" rel='noreferrer' href='/register'>register</a> online
									</div>
								</div>
							</div>
							<div className='switchStepTabItem'>
								<img style={{ width: '46px' }} src='https://cms.computicket.com/api/assets/mma/32c25da8-2e55-460d-a7ee-55a5984b18ab/sa2.png?version=0' />
								<div className='switchStepTabText'>
									<div >
										<b>Sign in</b> &amp; download your <b>Bank Confirmation Letter</b>
									</div>
								</div>
							</div>
							<div className='switchStepTabItem'>
								<img style={{ width: '46px' }} src='https://cms.computicket.com/api/assets/mma/16b4f4e1-4d33-4849-8e4b-87017c5099d6/sa3.png?version=0' />
								<div className='switchStepTabText'>
									<div><b>SWITCH ONLINE:</b></div>
									<div >
										Download the <b>SASSA</b> form <a style={{ borderBottom: '1px solid #aaa' }} target="_blank" rel='noreferrer' href='https://cms.computicket.com/api/assets/mma/88454cb5-6496-4613-8f29-b7b50030913a/consent-form-for-bank-payment-african-bank-to-be-completed-by-customer-v1.1.pdf?version=0' download="consent-form-for-bank-payment-online">here</a>
									</div>
								</div>
							</div>
							<div className='switchStepTabItem'>
								<img style={{ width: '46px' }} src='https://cms.computicket.com/api/assets/mma/951861e2-c1f6-4132-9dc9-ed1708bd4702/sa4.png?version=0' />
								<div className='switchStepTabText'>
									<div><b>SWITCH ONLINE:</b></div>
									<div >
										Take your printed <b>Bank Confirmation Letter</b>, completed <b>SASSA</b> switch form and original <b>ID</b> to your nearest <b>SASSA office</b>
									</div>
								</div>
							</div>
							<div className='switchStepTabItem stepButtonPadding' >
								<div className='switchStepTabBtnContainer'>
									{/* <div className='switchStepTabBtnItem'>
										<a className='btn-switch-tab-green' target="_blank" href='https://bit.ly/2SSMKCT ' rel="noreferrer" >
											Register via WhatsApp
										</a>
									</div> */}
									<div className='switchStepTabBtnItem'>
										<a className='btn-switch-tab-black' target="_blank" href='https://bit.ly/3nni5HM' rel="noreferrer" >
											Register via app
										</a>
									</div>
									<div className='switchStepTabBtnItem'>
										<a className='btn-switch-tab-purple' target="_blank" href='/register' rel="noreferrer" >
											Register online
										</a>

									</div>
								</div>
							</div>


						</div>
					</div>
				</TabPanel>

				<TabPanel key="tab2">
					<div
						id='business-tabs-content' style={{ display: 'block' }}
						className={`business-tabs-content business-tabs-content-srd`}
					>
						<div className='business-tabs-switch-content-text switchTabInsideHeader' style={{ paddingTop: '50px' }}>
							<h2 style={{ margin: 'auto' }}> Switch your SRD grant </h2>
						</div>

						{/* <p className='fade-text'> "test" </p> */}
						<div className='switchStepsTabItemContainer'>
							{/* <div className='switchStepsTabItemContainerSubHead'>Get <b>R25 VOUCHERS*</b> when your first Social Relief of Distress (SRD) grant is paid into your Money Market Account!</div> */}
							<div className='switchStepTabItem'>
								<img style={{ width: '46px' }} src='https://cms.computicket.com/api/assets/mma/e1ed218d-2326-4a5a-a026-50a152f23ee7/bs1.png?version=0' />

								<div className='switchStepTabText'>
									<div><b>REGISTER:</b></div>
									<div >
										<a style={{ borderBottom: '1px solid #aaa', fontWeight: 'bold' }} target="_blank" rel='noreferrer' href='https://bit.ly/3nni5HM'>Download</a> <b /> the Shoprite app or <a style={{ borderBottom: '1px solid #aaa', fontWeight: 'bold' }} target="_blank" rel='noreferrer' href='/register'>register</a> online
									</div>
								</div>
							</div>
							<div className='switchStepTabItem'>
								<img style={{ width: '46px' }} src='https://cms.computicket.com/api/assets/mma/4dc9bf75-75df-40c4-a58d-b1a211e22159/bs2.png?version=0' />
								<div className='switchStepTabText'>
									<div >
										You will receive an SMS with your bank account details. You can also sign into your Money Market Account to view them.
									</div>
								</div>
							</div>
							<div className='switchStepTabItem'>
								<img style={{ width: '46px' }} src='https://cms.computicket.com/api/assets/mma/6b0ba1f8-dacf-4941-b35d-f99356f25074/bs3.png?version=0' />
								<div className='switchStepTabText'>
									<div >
										Switch on the <b>SASSA</b> website <a href='https://srd.sassa.gov.za/' target='_blank' rel="noreferrer"><u>here</u></a><br />
										Scroll down to the section called<br /> “How do I change my banking details”
									</div>
								</div>
							</div>
							<div className='switchStepTabItem stepButtonPadding' >
								<div className='switchStepTabBtnContainer'>
									{/* <div className='switchStepTabBtnItem'>
										<a className='btn-switch-tab-green' target="_blank" href='https://bit.ly/2SSMKCT ' rel="noreferrer" >
											Register via WhatsApp
										</a>
									</div> */}
									<div className='switchStepTabBtnItem'>
										<a className='btn-switch-tab-black' target="_blank" href='https://bit.ly/3nni5HM' rel="noreferrer" >
											Register via app
										</a>
									</div>
									<div className='switchStepTabBtnItem'>
										<a className='btn-switch-tab-purple' target="_blank" href='/register' rel="noreferrer" >
											Register online
										</a>

									</div>
								</div>
							</div>


						</div>
					</div>
				</TabPanel>
				<TabPanel key="tab3">
					<div
						id='business-tabs-content' style={{ display: 'block' }}
						className={`business-tabs-content business-tabs-content-business`}
					>
						<div className='business-tabs-switch-content-text switchTabInsideHeader' style={{ paddingTop: '50px' }}>
							<h2 style={{ margin: 'auto' }}> Switch your salary </h2>
						</div>

						{/* <p className='fade-text'> "test" </p> */}
						<div className='switchStepsTabItemContainer'>
							<div className='switchStepsTabItemContainerSubHead'>Get <b>R100 VOUCHERS*</b> when your first salary greater than R800, and less than R10 000 is paid into your Money Market Account!</div>
							<div style={{ fontSize: "16px", marginTop: '5px' }}>See account limits <u><a href='/account-fees-and-limits'>here</a></u></div>
							<div className='switchStepTabItem'>
								<img style={{ width: '46px' }} src='https://cms.computicket.com/api/assets/mma/2af59762-d63d-4e5e-9d6e-1e28190a05ff/ss1.png?version=0' />

								<div className='switchStepTabText'>
									<div><b>REGISTER:</b></div>
									<div >
										<a style={{ borderBottom: '1px solid #aaa', fontWeight: 'bold' }} target="_blank" rel='noreferrer' href='https://bit.ly/3nni5HM'>Download</a> <b /> the Shoprite app or <a style={{ borderBottom: '1px solid #aaa', fontWeight: 'bold' }} target="_blank" rel='noreferrer' href='/register'>register</a> online
									</div>
								</div>
							</div>
							<div className='switchStepTabItem'>
								<img style={{ width: '46px' }} src='https://cms.computicket.com/api/assets/mma/e54bb631-7299-4cbc-b1ed-16cfca8a1bbe/ss2.png?version=0' />
								<div className='switchStepTabText'>
									<div >
										<b>Sign in</b> &amp; download your <b>Bank Confirmation Letter</b>
									</div>
								</div>
							</div>
							<div className='switchStepTabItem'>
								<img style={{ width: '46px' }} src='https://cms.computicket.com/api/assets/mma/9b4ccfab-7f0a-430f-961d-ad22f300fc68/ss3.png?version=0' />
								<div className='switchStepTabText'>
									<div >
										<b>Share</b> your letter with HR or Payroll Department
									</div>
								</div>
							</div>
							<div className='switchStepTabItem stepButtonPadding' >
								<div className='switchStepTabBtnContainer'>
									{/* <div className='switchStepTabBtnItem'>
										<a className='btn-switch-tab-green' target="_blank" href='https://bit.ly/2SSMKCT ' rel="noreferrer" >
											Register via WhatsApp
										</a>
									</div> */}
									<div className='switchStepTabBtnItem'>
										<a className='btn-switch-tab-black' target="_blank" href='https://bit.ly/3nni5HM' rel="noreferrer" >
											Register via app
										</a>
									</div>
									<div className='switchStepTabBtnItem'>
										<a className='btn-switch-tab-purple' target="_blank" href='/register' rel="noreferrer" >
											Register online
										</a>

									</div>
								</div>
							</div>
						</div>

						{/* <div className='buttons'>
										{panelButtons.map((button) => {
											return <Button button={button} key={button.id} />
										})}
									</div> */}

					</div>
				</TabPanel>

			</Tabs>
			<div className='bg-bot' style={{ backgroundColor: `${businessTabsBgColor[0].flatData.colorHex}` }}></div>
		</div>
	)
}

export default SwitchStepsTabs
